@import-normalize;

@import "variables";
@import "flex-grid";
@import "generics";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap");

:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --colors-red: #{$color__red};
  --colors-yellow: #{$color__yellow};
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  // overflow-x: hidden;
  height: -webkit-fill-available;
    
  font-size: 14px; // for 16px based size, its eq. to 14px
  @media #{$extra-large-and-up} {
    font-size: 16px;
  }
}

html,
body {
  -webkit-overflow-scrolling: touch !important;
}

img{
  max-width: 100%;
}

body {
  background: $bg-color;
  margin: 0;
  padding: 0;
  font-family: Roboto;
  cursor: default;
  font-weight: normal;
  color: $text-primary;
  line-height: $line-height-body;
  
  font-size: 14px; // for 16px based size, its eq. to 14px
  @media #{$extra-large-and-up} {
    font-size: 16px;
  }
}

.container {
  @include container;
}

.content {
  padding-top: 20px;
  padding-bottom: 30px;

  @media #{$large-and-up} {
    min-height: 45vh;
    padding-top: 30px;
    padding-bottom: $header-height + 30px;
  }
}

h1 {
  font-size: $font-size-h1;
  line-height: 2rem;
}
h2 {
  font-size: 1.500rem;
  @media #{$large-and-up} {
    font-size: $font-size-h2;
  }
}

h3 {
  font-size: 1.250rem;
  line-height: 2rem;
  @media #{$large-and-up} {
    font-size: $font-size-h3;
  }
}

h5 {
  font-size: 1.250rem;
  line-height: 1.8rem;
  font-weight: normal;
  @media #{$large-and-up} {
    font-size: $font-size-h5;
  }
}

h6 {
  font-size: 1.250rem;
  line-height: 1.8rem;
  margin: 0;
  @media #{$large-and-up} {
    font-size: $font-size-h6;
  }
}

h1,h2,h3,h4,h5,h6{
  word-break: break-word;
}

p {
  @include text;
}

button{
  white-space: nowrap;
}

hr {
  border: none;
  height: 1px;
  color: rgb(233, 233, 233);
  background-color: rgb(233, 233, 233);
}
// .MuiInputBase-sizeSmall{
//   // font-size: 1.3em !important;
//   // line-height: 1.3em !important;
//   input{
//     padding-top: 12px;
//     padding-bottom: 12px;
//   }
// }

.widget {
  margin-bottom: 2rem;

  .widget__title {
    font-weight: normal;
    letter-spacing: $letter-spacing-low;
    margin: .5rem 0;
  }
}

.header {
  .MuiBadge-badge {
    transform: scale(0.8) translate(30%, -20%);
  }
}
.noDataMessage {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title, p{
    color: $text-tertiary;
  }
}

.authDialog {
  .MuiOutlinedInput-root {
    border-radius: 8px;
    input {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  h2 + p {
    font-size: $font-size-p2 !important;
    margin: -0.5rem 0 1rem 0;
  }
}

#user-menu {
  .MuiPaper-root {
    margin-top: 2px;
    min-width: 240px;
  }
}

.MuiFormLabel-asterisk {
  color: $color__red;
}

.AccountInformation,
.ProfileCompletion {
  .MuiFormControl-root {
    margin-bottom: 1.5rem;

    .MuiSelect-select,
    input {
      padding: 0.7rem 0;
    }

    .MuiInputLabel-root {
      color: $text-primary;
      font-weight: 400;
      font-size: 1.250rem;
      transform: translate(0, -3px) scale(0.9);
      &.Mui-focused {
        color: $primary-color;
        
      }
    }
  }
}

.MuiOutlinedInput-root {
  input {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &.MuiInputBase-sizeSmall {
    input {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

.MuiInputLabel-outlined.MuiInputLabel-animated {
  transform: translate(14px, 12px) scale(1);
  &.MuiInputLabel-shrink {
    transform: translate(14px, -9px) scale(0.8);
  }
}

.MuiInputLabel-outlined.MuiInputLabel-animated {
  transform: translate(14px, 9px) scale(1);
}

.MuiDialog-root {
  &.centered {
    .MuiDialogTitle-root {
      text-align: center;
      padding: 30px;
    }
    .MuiDialogActions-root {
      justify-content: center;
      padding: 15px;
    }
  }
}

.MuiDialog-container {
  @media #{$medium-and-up} {
    & > div {
      min-width: 500px;
    }
  }
}

h2.MuiDialogTitle-root {
  // font-size: $font-size-lg - 0.2;
  // font-weight: bold;
  // padding: 0.1rem 0;
  line-height: 1.6em;
}

div.MuiDialogContent-root {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.MuiPaper-root.MuiPaper-rounded {
  border-radius: $border-radius;

  &.MuiDialog-paperFullScreen{
    border-radius: 0;
  }
}

ul.MuiMenu-list {
  padding: 0;

  li {
    font-size: $font-size-sm;
    letter-spacing: 0.02em;
    padding: 0.7rem 1rem;
  }
}

a.read-more {
  color: $primary-color;
  text-decoration: none;
}


a.MuiMenuItem-root,
li.MuiMenuItem-root {
  letter-spacing: 0.02em;
  padding: 0.7rem 1rem;
  font-size: 0.875rem;
  &:hover {
    background-color: rgba($primary-color, 0.06);
  }
}

.MuiTouchRipple-child {
  background-color: $primary-color !important;
}

.MuiAvatar-img {
  background: rgb(235, 235, 235);
}

.UserPanel {
  .MuiMenuItem-root {
    font-size: 1rem;
    font-weight: 600;
    padding: 0.7rem 0.5rem;
    border-radius: $border-radius;
  }
}

.isMobile {
  .Home {
    .serviceSlider {
      .slide {
        position: relative;
      }

      img {
        height: 500px;
        width: auto;
        border-radius: 0;
      }

      h3 {
        background: #3da3f7b2;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: $border-radius;
        position: absolute;
        bottom: 2rem;
        left: 1rem;
        letter-spacing: 0;
        font-size: 1.125rem;

        svg {
          vertical-align: middle;
        }
      }
    }
  }
}

$slickDotsSize: 10px;

.slick-slider .slick-dots {
  bottom: 15px;

  li {
    margin: 0;
    padding: 0;
    width: $slickDotsSize;
    height: $slickDotsSize;

    button {
      padding: 0;
      width: $slickDotsSize;
      height: $slickDotsSize;
      &:before {
        color: white;
        opacity: 1;
        width: $slickDotsSize;
        height: $slickDotsSize;
        line-height: $slickDotsSize;
      }
    }

    &.slick-active button:before {
      opacity: 1;
      color: $primary-color;
    }
  }
}

.slick-dots li.slick-active button::before {
  color: $primary-color;
}
