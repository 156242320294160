@import "../../variables.scss";

.header {
  height: $header-height;
  background: #ffffff;

  &.dark {
    background: #303030;
  }

  .headerInner {
    @include container;
    height: $header-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }

  .backButton {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    margin-right: 2rem;
    cursor: pointer;
    width: 28px;
  }

  .logoWrapper {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
  }

  .loginButtons {
    button {
      text-transform: inherit;
      color: white;
      border-radius: 30px;
      pointer-events: none;
      font-size: 1rem;
    }

    button:last-child {
      background: #a8a8a8;
      color: #6e6d71;
    }
  }

  .appButton {
    font-size: 1rem;
    width: 151px;
    height: 40px;
    background: #303030 !important;
    border-radius: 40px;
    color: white;
    text-transform: inherit;
  }
}

.isMobile {
  .header {
    background: white;
    box-shadow: 0px 0.7px 0px rgba(0, 0, 0, 0.09);
    .logoWrapper {
      color: $text-primary;
      img {
        display: none;
      }
    }
    .backButton {
      margin-right: 0.2rem;
      display: flex;
      img {
        filter: invert(1);
        width: 20px;
        height: 20px;
      }
    }
  }
}
