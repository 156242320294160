$color__yellow: rgb(241, 196, 15);
$color__orange: rgb(255, 165, 0);
$color__green: rgb(27, 209, 155);
$color__light-green: rgb(190, 255, 239);
$color__red: rgb(240, 58, 95);
$color__blue: rgb(0, 149, 246);

$bg-color: rgb(253, 253, 253);
$primary-color: #17A2B8;
$secondary-color: $color__blue;

$text-primary: #333333;
$link-color: #222;

$shadow-d1: 0 3px 1px -2px rgba(0, 0, 0, 0.18);

$text-secondary: #6e6d71;
$text-tertiary: #a8a8a8;

$letter-spacing-bit: 0.02em;
$letter-spacing-low: 0.04em;
$letter-spacing-med: 0.06em;

$font-size-sm: 14px;
$font-size-md: 1em; // base on browser. normally eq. to 16px
$font-size-lg: 1.625em;
$font-size-xl: 2.5em;
$font-size-2xl: 3em;

/* rems unit*/
$font-size-sm-r: 14px;
$font-size-md-r: 1rem; // base on browser. normally eq. to 16px
$font-size-lg-r: 1.625em;
$font-size-xl-r: 2rem;
$font-size-2xl-r: 3rem;

$font-size-h1: 3rem;
$font-size-h2: 2.5rem;
$font-size-h3: 1.875rem;
$font-size-h5: 1.375rem;
$font-size-h6: 1.125rem;

$font-size-p1: 1.500rem;
$font-size-p2: 1.125rem;

$line-height-body: 1.3rem;
$line-height-text: 1.7rem;

$header-height: 56px;
$header-search-height: 50px;

$navbar-height: 63px;

$content-margin-top: 70px;
$content-margin-bottom: 85px;

$border-radius: 4px;
$inputs-height: 45px;

$border-regular: 1px solid #e9e9e9;

// Media Query Ranges
$small-screen: 420px !default;
$small-screen-up: 421px !default;
$medium-screen: 768px !default;
$medium-screen-up: 769px !default;
$large-screen: 1366px !default;
$large-screen-up: 1367px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;

// usage:
// @media #{$medium-and-up} {
//     float: left;
// }

$container-gap: .75rem;
$container-max-width: 960px;
$container-max-width-xl: 1366px;

@mixin text {
  //font-size: 1.1rem;
  line-height: $line-height-text;
  word-break: break-word;
  word-wrap: anywhere;
}

@mixin container {
  padding-right: $container-gap;
  padding-left: $container-gap;
  max-width: $container-max-width;
  // width: 100%;
  margin-right: auto;
  margin-left: auto;

  @media only screen and (min-width :1600px) {
    max-width:$container-max-width-xl;
  }
}

@function str-split($string, $separator) {
  // empty array/list
  $split-arr: ();
  // first index of separator in string
  $index: str-index($string, $separator);
  // loop through string
  @while $index != null {
    // get the substring from the first character to the separator
    $item: str-slice($string, 1, $index - 1);
    // push item to array
    $split-arr: append($split-arr, $item);
    // remove item and separator from string
    $string: str-slice($string, $index + 1);
    // find new index of separator
    $index: str-index($string, $separator);
  }
  // add the remaining string to list (the last item)
  $split-arr: append($split-arr, $string);

  @return $split-arr;
}

/**
 * add rules for every element if parent has .isMobile class -
 * to be used is css module
 * https://gist.github.com/RezaRamezanpour/a80653a56b2eb4559a376821e6f9cc18
*/
@mixin onMobile($tagsString) {
  $tags: str-split($tagsString, ", ");
  @each $tag in $tags {
    @at-root :global(.isMobile) #{$tag} {
      @content;
    }
  }
}

