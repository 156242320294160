@import "../../variables.scss";

.heroOuter {
  background-image: linear-gradient(45deg, rgb(38, 147, 209), rgb(35, 176, 211));
}

.hero {
  position: relative;
  padding: 1rem;
  height: 150vw;
  color: white;
  background-image: url("../../assets/images/hero-cover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 10% center;

  @media #{$medium-and-up} {
    //height: 44vw;
    height: 36.8vw;
    background-position: center;
  }

  & > div {
    max-width: 90vw;
  }
  // fix
  // background-size: 100% 100%;
  // width: 100vw;
  // height: 630px;
}

.heroContent {
  max-width: 700px;
  margin-left: auto;
  // margin-right: auto;
  text-align: left;
  letter-spacing: $letter-spacing-med;
  height: 100vw;
  display: flex;
  flex-direction: column;
  background: rgba(10, 10, 10, 0.4);
  border-radius: 25px;
  margin-top: 4vw;
  @media #{$medium-and-up} {
    display: block;
    padding: 2rem 0;
    text-align: center;
    height: auto;
  }

  @media #{$large-and-up} {
    padding: 2rem 0;
  }

  h1 {
    flex: 1;
    line-height: 3rem;
    @media #{$medium-and-up} {
      flex: none;
    }
  }

  label {
    margin-top: 1rem;
    font-size: 1.563rem;
    font-weight: 700;
    display: block;
  }
  img {
    width: 70px;
    height: 70px;
    // filter: invert(1);
  }

  h5 {
    letter-spacing: $letter-spacing-low;
    font-weight: 400;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  @media screen and (min-width: 1366px) {
    position: relative;
    top: 5vh;
  }

  @media screen and (min-width: 2500px) {
    position: relative;
    top: 15vh;
  }
}

.search {
  border-radius: $border-radius;
  background-color: transparent;
  border: 1px solid white;
  display: flex;
  align-items: center;
  max-width: 70vw;
  height: 42px;

  @media #{$medium-and-up} {
    max-width: 400px;
    margin: 0 auto;
  }

  input {
    font-size: 1rem;
    flex: 1;
    background: transparent;
    border: none;
    color: white;
    height: 42px;
    width: 100%;
  }
}

.searchIcon {
  filter: invert(1);
  width: 42px;
  height: 42px;
  padding: 0.7rem;
}

.searchButton {
  background: rgba(255, 255, 255, 1);
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: $border-radius;
  padding: 0;
  border: none;

  img {
    filter: invert(0) !important;
    opacity: 0.7;
    vertical-align: middle;
  }
}

.about {
  h2 {
    font-weight: 700;
    font-size: 4.5rem;
    color: $primary-color;
    margin: 0;
    span {
      display: block;
      line-height: 60px;
      &:first-child {
        color: #a8a8a8 !important;
      }
    }
  }

  p {
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: justify;
  }
}

.services {
  h2 {
    font-weight: 700;
    font-size: 4.5rem;
    color: #c89ce9;
    margin: 0;
    span {
      display: block;
      line-height: 60px;
      &:first-child {
        font-size: 2.25rem;
        color: #a8a8a8 !important;
        line-height: 36px;
      }
      &:last-child {
        font-size: 4.5rem;
        color: #a8a8a8 !important;
      }
    }
  }
}
.service {
  // position: relative;
  text-decoration: none;
  text-align: center;

  img {
    max-width: 100%;
    width: 207px;
    height: auto;
    object-fit: cover;
    border-radius: $border-radius;
    background-color: rgb(243, 243, 243);
  }
}

.mobileServicesHeader {
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 9;
  margin-top: 3rem;
  color: white;
  padding: 1rem;
  border-radius: $border-radius;
}

.serviceTitle {
  letter-spacing: $letter-spacing-low;
  font-weight: 400;
  margin: 0.2rem auto;
  color: $text-primary;
  font-size: 14px;
}

.register {
  margin-top: 4rem;
  text-align: center;
  h4 {
    margin: 1rem 0;
    font-style: normal;
    font-weight: normal;
    font-size: 1.875rem;
    line-height: 35px;
    color: #308d94;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.03em;
  }

  a {
    padding: 7.5px;
    width: 126px;
    height: 34px;
    border: 1px solid #308d94;
    border-radius: 30px;
    color: #8d8d8d;
    text-transform: inherit;
    font-weight: normal;
  }
}

.registerSeparator {
  margin: 0 auto;
  width: 540px;
  height: 1.5px;
  background: #4ba2bb;
  display: block;
}

.consultant {
  background-image: url("../../assets/images/home-consultant-cta.jpg");
  background-color: #ddd;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 550px;
  color: white;
  padding-top: 220px;
  margin: 0;
  margin-top: -5px;

  @media #{$medium-and-up} {
    height: 400px;
  }

  h5 {
    font-size: $font-size-lg;
    font-weight: normal;
    margin: 0;
  }
  h2 {
    font-size: $font-size-2xl-r;
    margin: 1.5rem 0;
    font-weight: bold;
  }
}

.dentistCta {
  h2 {
    font-weight: 700;
    font-size: 4.5rem;
    color: #f1b707;
    margin: 0;
    span {
      display: block;
      line-height: 60px;
      &:first-child {
        font-size: 2.25rem;
        color: #a8a8a8 !important;
        line-height: 36px;
      }
      &:last-child {
        font-size: 4.5rem;
        color: #a8a8a8 !important;
      }
    }
  }
  p{
    text-align: justify;
  }
}

.labsCta {
  max-width: 90%;
  @media #{$large-and-up} {
    max-width: 45vw;
  }
  margin: 0 auto;

  img {
    max-width: 100%;
  }

  p {
    font-style: normal;
    font-weight: normal;
    text-align: justify;

    /* Light/Primary */
    color: #303030;
  }

  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 4.5rem;
    margin: 0;
    margin-bottom: 2rem;
    span {
      display: block;
      line-height: 60px;
      &:first-child {
        font-size: 2.25rem;
        color: #c5c9cc !important;
        line-height: 36px;
      }
      &:last-child {
        font-size: 4.5rem;
        color: #3da3f7 !important;
      }
    }
  }
}

.supplierCta {
  h2 {
    font-weight: 700;
    font-size: 4.5rem;
    color: #c297b6;
    margin: 0;
    span {
      display: block;
      line-height: 60px;
      &:first-child {
        font-size: 2.25rem;
        color: #a8a8a8 !important;
        line-height: 36px;
      }
      &:last-child {
        font-size: 4.5rem;
        color: #c297b6 !important;
      }
    }
  }
}
