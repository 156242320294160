@import "../../variables.scss";

.footer {
  background: #444445;
  padding: 4rem 2rem;
  position: relative;
  padding: 1rem;
  height: 50vw;
  color: white;
  background-image: url("../../assets/images/footer-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 10% center;

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;

    a {
      display: block;
      margin-bottom: .8rem;
    }
  }

  h3 {
    font-size: 1rem;
    margin: 0 0 1rem 0;
    color: white;
    white-space: nowrap;
    @media #{$medium-and-up} {
      font-size: 15px;
    }
  }

  a {
    color: $text-tertiary;
    text-decoration: none;
  }
}

.contact{
  h5{
    color: white;
    font-size: 1rem;
    margin: .1rem 0 0 0;
  }
  p{
    color: $text-tertiary;
    margin: 0 0 .8rem 0;
    line-height: 1.3rem;
  }
}

.primary,.secondary{
  max-width: 780px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
}

.secondary{
  text-align: center;
  flex-direction: column;
}

.about{
  justify-content: center;
  p{
    line-height: 1.2rem;
    color: #777;
    font-weight: 700;
  }
}

.logoType {
  display: inline-block;
  font-size: 2rem;
  font-weight: lighter;
  margin-top: 1rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.logoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  text-decoration: none;
  margin-bottom: 3rem;
}

.widget {
}

.socialLinks {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  a {
    margin: 0 0 1rem 0;
    display: flex;
    align-items: center;

    @media #{$medium-and-up} {
      margin: 0 1.2rem;
      display: inline-block;
    }

    img {
      width: 22px;
      height: 22px;
      filter: invert(1);
    }
    span {
      margin-left: 1rem;
      @media #{$medium-and-up} {
        display: none;
      }
    }
  }
}
