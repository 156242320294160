$space_amounts: (0, 0.2, 0.5, 1, 2, 3, 6);
$sides: (top, bottom, left, right, all);

@for $i from 1 through length($space_amounts) {
  $space: nth($space_amounts, $i);
  @each $side in $sides {
    @if $side == "all" {
      .m-#{$i - 1} {
        margin: #{$space}rem;
      }

      .p-#{$i - 1} {
        padding: #{$space}rem;
      }
    } @else {
      .m#{str-slice($side, 0, 1)}-#{$i - 1} {
        margin-#{$side}: #{$space}rem;
      }

      .p#{str-slice($side, 0, 1)}-#{$i - 1} {
        padding-#{$side}: #{$space}rem;
      }
    }
  }
}

.bg-primary {
  background-color: $primary-color;
}
.bg-secondary {
  background-color: $secondary-color;
}
.fg-primary {
  color: $primary-color;
}
.fg-secondary {
  color: $secondary-color;
}

.fg-red {
  color: $color__red;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-primary {
  color: $text-primary;
}
.text-secondary {
  color: $text-secondary;
}
.text-tertiary {
  color: $text-tertiary;
}
.d-flex {
  display: flex;
}
.d-none {
  display: none;
}
.flex-space-between {
  justify-content: space-between;
}
.flex-fill {
  flex: 1;
}
.flex-column{
  flex-direction: column;
}
.flex-row{
  flex-direction: row;
}
.flex-center {
  align-items: center;
}
.flex-c-center {
  justify-content: center;
}
.flex-c-end {
  justify-content: end;
}

.fs-sm {
  font-size: $font-size-sm;
}
.fs-md {
  font-size: $font-size-md;
}
.fs-lg {
  font-size: $font-size-lg;
}
.fs-xl {
  font-size: $font-size-xl;
}
.fs-2xl {
  font-size: $font-size-2xl;
}

.fs-p1 {
  font-size: $font-size-p1 !important;
}
.fs-p2 {
  font-size: $font-size-p2;
}
.fs-root{
  font-size: 1rem;
}
.fw-bold {
  font-weight: 700;
}
.fw-regular {
  font-weight: 400;
}
.fw-medium {
  font-weight: 500;
}
.whitespace-nowrap{
  white-space: nowrap;
}
.letter-spacing-low {
  letter-spacing: $letter-spacing-low;
}
.letter-spacing-med {
  letter-spacing: $letter-spacing-med;
}
.letter-spacing-high {
  letter-spacing: 0.09em;
}
.rounded {
  border-radius: 30px;
}
.td-none {
  text-decoration: none;
}
.tt-none{
  text-transform: inherit;
}
.full-width{
  width: 100%;
}
.b-b-regular {
  border-bottom: $border-regular;
}
.b-t-regular {
  border-top: $border-regular;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}
